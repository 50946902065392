import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import { sbEditable } from '@storyblok/storyblok-editable';
import { getSrc } from 'gatsby-plugin-image';
import { NavigationContext } from 'context/navigationContext';
import { useContentFunctor, useMetaDataQuery, useStoryblok } from 'hooks';
import { Layout, Seo } from 'containers';
import { DynamicComponent } from 'components/DynamicComponent';
import { collectionPageBreadcrumbsSchema, faqSchema, flickrSchema } from 'utilities';

const Page = ({ pageContext, location, data }) => {
    const { siteUrl } = useMetaDataQuery();
    const mainNavigation = pageContext.mainNavigation.items;
    const { setNavItems } = useContext(NavigationContext);
    const story = useStoryblok(pageContext.story, location);
    const isHomepage = pageContext.url === 'homepage';
    const contentBody = useContentFunctor(story?.content.body);
    const featuredImageUrl = `${siteUrl}${getSrc(story.content.featured_image)}`;
    const { meta_created_at, meta_published_at, slug } = story;
    const { faq, flickr, headline, hero3Category, hero3Subcategory, reference } = contentBody;
    const { meta_title, meta_description, schema_type } = story.content;
    const flickrObjs = flickrSchema(story, siteUrl, flickr);

    const schema = {
        url: siteUrl,
        image: featuredImageUrl,
        headline: headline,
        title: meta_title,
        description: meta_description,
        published: meta_created_at,
        modified: meta_published_at,
        reference: reference,
    };

    const parsedRecentPosts = data.recentPosts.edges.map((recentPost) => ({
        content: JSON.parse(recentPost.node.content).article_title,
        slug: recentPost.node.slug,
    }));

    const recentPostsForStoryblok = parsedRecentPosts.map((el) => ({
        title: el.content,
        slug: el.slug,
    }));

    useEffect(() => {
        setNavItems(mainNavigation);
    }, []);

    const components = story.content.body?.map((blok) => (
        <DynamicComponent
            blok={blok}
            key={blok._uid}
            isHomepage={isHomepage}
            recentPosts={story.recent_posts || recentPostsForStoryblok}
        />
    ));

    return (
        <Layout type="page">
            <Seo
                isHomepage={pageContext.url === 'homepage'}
                title={meta_title}
                description={meta_description}
                url={siteUrl}
                image={featuredImageUrl}
                published={meta_created_at}
                modified={meta_published_at}
                isPage={true}
                type={schema_type}
                schema={schema}
                faq={faqSchema(faq)}
                flickr={flickrObjs}
                breadcrumbs={collectionPageBreadcrumbsSchema(hero3Category, hero3Subcategory, slug)}
            />
            <div {...sbEditable(story.content)}>
                <section>{components}</section>
            </div>
        </Layout>
    );
};

export default Page;

export const query = graphql`
    query {
        recentPosts: allStoryblokEntry(
            filter: { field_component: { eq: "post" } }
            limit: 10
            sort: { fields: published_at, order: DESC }
        ) {
            edges {
                node {
                    content
                    slug
                }
            }
        }
    }
`;
